import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import useAPI from '../../utils/api';
import AppLoader from '../../shared/AppLoader';
import useFetch from '../../utils/useFetch';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MuiButton from '@mui/material/Button';
import Button from '../../shared/Button';
import AppGrid from '../../shared/Grid';
import Box from '@mui/material/Box';
import CouponInput from '../../shared/CouponInput';
import ScheduleSwitch from '../../shared/ScheduleSwitch';
import { iconImages } from '../../shared/cardIcons';
import '../../assets/styles/StripeCheckout.scss';
import { SitePath } from '../../shared/SiteLink';
import { useSnackbar } from 'notistack';
import PayPalButton from '../../shared/PayPalButton';
import { usePayPal } from '../../utils/PayPalProvider';
import Loader from 'react-loader-spinner';
import { analytics } from '../../utils/analytics';
import { withTracking } from '../../shared/Tracking';
import { Title } from '../../SiteRouter';
import ServiceAddOnDialog from './ServiceAddOnDialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

function StripeCheckout({ site }) {
  const api = useAPI();
  const { planId } = useParams();
  const [coupon, setCoupon] = React.useState({ id: '', code: '' });
  const [schedule, setSchedule] = React.useState(planId.split('-')[1]);
  const [upgrading, setUpgrading] = React.useState(false);
  const [showServicePopup, setShowServicePopup] = React.useState(false);
  const { loading, status, proration } = useFetch(
    'proration',
    planId,
    coupon.code
  );
  const { enqueueSnackbar } = useSnackbar();

  // sessionStorage.removeItem('preselected_plan');

  useEffect(() => {
    if (site.installed && site.partner === 'shopify') {
      return (window.location.href =
        'https://admin.shopify.com/charges/hellobar-email-popups-1/pricing_plans');
    }
  }, []);

  useEffect(() => {
    proration?.coupon &&
      coupon.id !== proration?.coupon?.id &&
      onCouponChange(coupon);
  }, [proration, coupon.id, coupon]);

  const onNewPaymentMethod = () => {
    api.createCheckoutSession(planId, coupon.code).then(([ok, response]) => {
      if (ok) {
        window.location.href = response.session_url;
      } else {
        enqueueSnackbar(
          'Something went wrong. Please try again or contact us.',
          { variant: 'error' }
        );
      }
    });
  };

  const onCouponChange = (coupon) => {
    setCoupon(coupon);
  };

  const upgradeUrl = SitePath(`/upgrade/${planId.split('-')[0]}-${schedule}`);
  const billingUrl = SitePath(`/settings/billing`);

  const handleScheduleChange = (schedule) => {
    setSchedule(schedule);
  };

  function successPayment() {
    setUpgrading(false);
    enqueueSnackbar(
      `Your site is on the ${proration.plan.product_name} plan now!`,
      { variant: 'success' }
    );
    analytics.upgraded(proration.plan);
    if (planId.includes('financial-')) {
      setShowServicePopup(true);
    } else {
      setTimeout(() => {
        window.location = billingUrl;
      }, 2000);
    }
  }

  function errorPayment() {
    enqueueSnackbar('Something went wrong.', { variant: 'error' });
  }

  const handlePayment = async (event) => {
    event.preventDefault();
    setUpgrading(true);
    const [ok] = await api.upgrade(planId, coupon.code);

    if (ok) {
      successPayment();
    } else {
      errorPayment();
    }
  };

  const onApprove = async (error, payload) => {
    setUpgrading(true);
    if (error) {
      console.error('PayPal error', error);
    } else {
      const [ok] = await api.subscribeWithPayPal(
        payload.nonce,
        planId,
        coupon.code
      );
      if (ok) {
        successPayment();
      } else {
        errorPayment();
      }
    }
  };

  if (status === 'no_content') {
    onNewPaymentMethod();
  }

  const paypalContext = usePayPal();

  const brandIcon = (payment_method) =>
    iconImages[payment_method.brand.toLowerCase()];

  return (
    <React.Fragment>
      <AppLoader visible={loading || status === 'no_content'} />
      {status === 'fetched' && (
        <Container maxWidth="md">
          {proration.plan.schedule !== schedule && (
            <Redirect push to={upgradeUrl} />
          )}
          {proration.plan.id === proration.current_plan_id && (
            <Redirect push to={billingUrl} />
          )}
          <Title
            site={site}
            value={`Upgrade to ${proration.plan.product_name} ${schedule}`}
          />

          <Grid container spacing={4}>
            <Grid item xs={12} md={5}>
              <Box
                bgcolor="#f0f7ff"
                p={2}
                borderRadius={1}
                display="flex"
                alignItems="center"
                mb={2}
              >
                <Box mr={2} color="#1976d2">
                  <TrendingUpIcon />
                </Box>
                <Box fontWeight="500" color="#1976d2">
                  {proration.plan?.product_name} plan customers see a 3x
                  improvement in conversions over the free plan.
                </Box>
              </Box>

              <Box bgcolor="#f8f9fa" p={3} borderRadius={1}>
                <Box fontWeight="600" fontSize={18} mb={2}>
                  Top Features Included:
                </Box>
                <Grid container spacing={2}>
                  {getPlanFeatures(proration.plan.product_name).map(
                    (feature, index) => (
                      <Grid item xs={12} key={index}>
                        <Box display="flex" alignItems="center">
                          <Box mr={1} color="success.main">
                            <CheckCircleIcon fontSize="small" />
                          </Box>
                          <Box>{feature}</Box>
                        </Box>
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={7}>
              <div className="ScheduleSwitch">
                <Grid>
                  <Grid
                    container
                    justifyContent="center"
                    className="OrderDetailsItem"
                  >
                    <Grid item xs={7}>
                      <ScheduleSwitch
                        default={schedule}
                        label="Pay "
                        onChange={handleScheduleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div className="ProductSummary">
                <Grid>
                  <Grid
                    container
                    justifyContent="center"
                    className="OrderDetailsItem"
                  >
                    <Grid item xs={12}>
                      <Box
                        fontWeight="500"
                        fontSize={16}
                        color="text.secondary"
                      >
                        {proration.plan.product_name === 'Professionals LeadGen'
                          ? 'Complete your purchase of '
                          : 'Upgrade to '}
                        {proration.plan.product_name}
                      </Box>
                      <Box fontWeight="700" fontSize={36} color="text.primary">
                        {proration.plan.interval === 'month'
                          ? proration.next_invoice.total
                          : proration.next_invoice.monthly_total}
                        {proration.prorated && ' prorated'}
                      </Box>
                      <Box
                        fontWeight="500"
                        fontSize={14}
                        color="text.secondary"
                      >
                        per month, per site, billed {proration.plan.interval}ly
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              {showServicePopup && !site.purchased_onboarding && (
                <ServiceAddOnDialog
                  site={site}
                  isOpen={true}
                ></ServiceAddOnDialog>
              )}
              <div className="OrderDetails">
                <div className="OrderDetails-items">
                  <Grid>
                    <Grid item xs={12}>
                      {proration.next_invoice.lines.map((line) => (
                        <Grid
                          key={line.description}
                          container
                          justifyContent="center"
                          className="OrderDetailsItem"
                        >
                          <Grid item xs={8}>
                            <Box
                              fontWeight="500"
                              fontSize={14}
                              color="text.primary"
                            >
                              {line.description}
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box
                              fontWeight="500"
                              fontSize={14}
                              textAlign="right"
                            >
                              {line.amount}
                            </Box>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </div>
                <div className="OrderDetails-footer">
                  <AppGrid className="OrderDetailsFooter-subtotal">
                    <Grid item xs={8}>
                      <Box fontWeight="500" fontSize={14} color="text.primary">
                        Subtotal
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box fontWeight="600" fontSize={14} textAlign="right">
                        {proration.next_invoice.subtotal}
                      </Box>
                    </Grid>
                  </AppGrid>

                  <AppGrid justifyContent="flex-start">
                    <CouponInput
                      planId={planId}
                      value={coupon}
                      onChange={onCouponChange}
                    />
                  </AppGrid>

                  {proration.next_invoice.discount && (
                    <AppGrid className="OrderDetailsFooter-subtotal-items">
                      <Grid container className="OrderDetailsSubtotalItem">
                        <Grid item xs={8}>
                          <Box color="text.secondary" fontSize={14}>
                            Discount
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            color="text.secondary"
                            fontSize={14}
                            textAlign="right"
                          >
                            -{proration.next_invoice.discount}
                          </Box>
                        </Grid>
                      </Grid>
                    </AppGrid>
                  )}

                  <AppGrid className="OrderDetails-total">
                    <Grid item xs={8}>
                      <Box fontWeight="600" fontSize={14}>
                        Total due today
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box fontWeight="600" fontSize={16} textAlign="right">
                        {proration.next_invoice.total}
                      </Box>
                    </Grid>
                  </AppGrid>
                </div>
                <div className="PaymentMethod">
                  {upgrading && (
                    <Grid style={{ textAlign: 'center' }}>
                      <Loader
                        height={10}
                        visible={upgrading}
                        type="ThreeDots"
                        color="#e8562a"
                      />
                    </Grid>
                  )}
                  {!upgrading && (
                    <Grid container>
                      <Grid item xs={6}>
                        {(!proration.upgrading ||
                          proration.paypal_account_email) && (
                          <Box fontWeight="600" fontSize={16} textAlign="left">
                            {paypalContext.paypal && (
                              <PayPalButton
                                paypalContext={paypalContext}
                                onApprove={onApprove}
                              />
                            )}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {proration.payment_method && (
                          <Box fontWeight="600" fontSize={16} textAlign="right">
                            <Button loading={upgrading} onClick={handlePayment}>
                              Pay with &nbsp;{' '}
                              {brandIcon(proration.payment_method)} ••••{' '}
                              {proration.payment_method.last4}
                            </Button>
                          </Box>
                        )}
                        {!proration.payment_method && (
                          <Box fontWeight="600" fontSize={16} textAlign="right">
                            <Button
                              loading={upgrading}
                              onClick={onNewPaymentMethod}
                            >
                              Pay with Credit Card
                            </Button>
                          </Box>
                        )}
                      </Grid>
                      {!proration.upgrading && proration.payment_method && (
                        <Grid item xs={12}>
                          <Box
                            borderTop={1}
                            p={3}
                            mt={2}
                            borderColor="grey.700"
                          >
                            <MuiButton
                              color="secondary"
                              variant="outlined"
                              fullWidth
                              loading={upgrading}
                              onClick={onNewPaymentMethod}
                            >
                              Pay with New Card
                            </MuiButton>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      )}
    </React.Fragment>
  );
}

function getPlanFeatures(planName) {
  const features = {
    Growth: [
      'Unlimited Pop-ups & Bars',
      'Up to 50,000 popup views/month',
      'A/B Testing',
      'Advanced Targeting',
      'Advanced Email list Integrations',
    ],
    Premium: [
      'Everything in Growth',
      'Up to 150,000 popup views/month',
      'Priority Support',
      'Premium Targeting',
      'Premium Email list Integrations',
    ],
    Elite: [
      'Everything in Premium',
      'Up to 500,000 popup views/month',
      'Dedicated Account Support',
      'Custom Targeting',
      'Custom Email list Integrations',
    ],
  };

  return features[planName] || [];
}

export default withTracking(StripeCheckout);
