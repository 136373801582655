import React from 'react';
import SidebarItem from './Sidebar/SidebarItem';
import Button from '../shared/Button';
import '../assets/styles/Sidebar.scss';
import '../assets/styles/_icons.scss';
import { editorURL } from '../utils/editor';
import NewSiteDialog from '../shared/NewSiteDialog';
import { withTracking } from '../shared/Tracking';
import { NavLink } from 'react-router-dom';
import HelpDialog from '../shared/HelpDialog';

function Sidebar(props) {
  const site = props.site;

  return (
    <div className="sidebar">
      <div
        className={`sidebar-nav-link no-hover ${
          props.isDisable ? 'disabled' : ''
        }`}
      >
        {site && (
          <React.Fragment>
            <ul>
              <div className="new-popup-wrapper">
                <NavLink to={editorURL(site, '/new/interstitial')}>
                  <Button
                    color="secondary"
                    variant="contained"
                    className="full-width"
                    style={{ padding: '12px 24px' }}
                    label={<strong>+ New Popup</strong>}
                  />
                </NavLink>
              </div>

              <SidebarItem
                name="Summary"
                path={`/sites/${site.id}/summary`}
                className="icon-summary"
              />

              <SidebarItem
                name="Install"
                path={`/sites/${site.id}/install`}
                className="icon-installmyself"
              />
              <SidebarItem
                name="Popups"
                path={`/sites/${site.id}/manage`}
                className="icon-manage"
              />
              <SidebarItem
                name="Targeting"
                path={`/sites/${site.id}/rules`}
                className=""
              />
              <SidebarItem
                name="Contacts"
                path={`/sites/${site.id}/contacts`}
                className="icon-contacts"
              />
              <SidebarItem
                name="Settings"
                path={`/sites/${site.id}/settings`}
                className="icon-settings"
              />
              <HelpDialog isOpen={false} showIcon={true} />
            </ul>
          </React.Fragment>
        )}
        {!site && (
          <div className="new-popup-wrapper">
            <NewSiteDialog isOpen={true} />
          </div>
        )}
      </div>
      <div className="sidebar-content">{props.children}</div>
    </div>
  );
}

export default withTracking(Sidebar);
