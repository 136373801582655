import React from 'react';
import { withStyles } from '@mui/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Button from '../shared/Button';
import '../assets/styles/_install.scss';
import '../assets/styles/_reveal.scss';
import '../assets/styles/_icons.scss';
import useAPI from '../utils/api';
import TextField from '@mui/material/TextField/TextField';
import FormControl from '@mui/material/FormControl/FormControl';
import { useSnackbar } from 'notistack';
import { tracking } from '../utils/tracking';
import { withTracking } from '../shared/Tracking';

const Accordion = withStyles({
  root: {
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
    marginTop: '1rem',
    borderRadius: '12px',
    border: '1px solid #f0f0f0',
    transition: 'all 0.3s ease',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginTop: '1rem',
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
    },
    '&:hover': {
      borderColor: '#e0e0e0',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: '0.5rem 1.5rem',
    '&$expanded': {
      minHeight: 60,
    },
  },
  content: {
    margin: '12px 0',
    '& i': {
      fontSize: '2rem',
      marginRight: '1.5rem',
      color: '#2196f3',
    },
    '& .summary-info': {
      flex: 1,
    },
    '& .reveal-title': {
      fontSize: '1.1rem',
      fontWeight: 600,
      marginBottom: '0.25rem',
    },
    '& p': {
      margin: 0,
      color: '#666',
      fontSize: '0.9rem',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const makeSnippet = (site) =>
  `<script src="https://my.hellobar.com/${site.snippet_id}.js" type="text/javascript" charset="utf-8" async="async"> </script>`;

const Summary = ({ site, user, trackEvent }) => {
  const API = useAPI();
  const [expanded, setExpanded] = React.useState('panel2');
  const [devEmail, setDevEmail] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // if (sessionStorage.getItem('preselected_plan')) {
  //   const plan = sessionStorage.getItem('preselected_plan');
  //   const upgradeUrl = generatePath("/sites/:id/upgrade/:plan", { id: site.id, plan });
  //   return (<Redirect to={upgradeUrl}/>);
  // }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleCopy = (event) => {
    if (event.target.select) {
      event.target.select();
    } else {
      const input =
        event.target.getElementsByTagName('input')[0] ||
        event.target.getElementsByTagName('textarea')[0];
      input?.select();
    }
    document.execCommand('copy');
    enqueueSnackbar('Copied!', { variant: 'success' });
  };

  const checkInstallation = async () => {
    setLoading(true);
    window.open(site.url + '?hb-check-install');
    setTimeout(async () => {
      const [, updated] = await API.checkSiteInstalled();
      setLoading(false);
      if (updated.installed) {
        enqueueSnackbar('Congrats! The snippet has been installed properly!', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Not installed yet. Need help? Let us know!', {
          variant: 'success',
        });
      }
    }, 2000);
  };

  const devEmailChanged = (event) => {
    setDevEmail(event.target.value);
  };

  const sendEmail = async (event) => {
    event.preventDefault();
    await API.emailDeveloper(site, devEmail);
    enqueueSnackbar(
      "We've sent the installation instructions to your developer!",
      { variant: 'success' }
    );
  };

  const openFreshchat = () => {
    tracking.freshchat.open();
  };

  return (
    <div id="sites-install" className="installation-page">
      <header className="install-header">
        <h1>Install Hello Bar Script</h1>
        <p className="subtitle">
          Once the script is installed, your dashboard will begin populating
          data all about your bars and visitors. This only needs to be done once
          per site.
        </p>
        <Button
          loading={loading}
          onClick={checkInstallation}
          variant="contained"
          style={{
            background: '#2196f3',
            color: 'white',
            padding: '0.75rem 2rem',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(33, 150, 243, 0.3)',
          }}
        >
          Check Installation
        </Button>
      </header>

      <h5 className="section-title">Choose Your Installation Method</h5>
      <Accordion
        className="reveal-wrapper"
        square
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <i className="icon-wordpress" />
          <div className="summary-info">
            <div className="reveal-title">I use WordPress</div>
            <p>
              If you use WordPress you'll love our super easy copy and paste
              integration. Get your site snippet here.
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="reveal-block wordpress-reveal">
            <div className="title">1) Copy Your Site Snippet</div>
            <div className="form-inline">
              <FormControl fullWidth>
                <TextField
                  onClick={handleCopy}
                  value={site.snippet_id}
                  size="small"
                  readOnly
                />
              </FormControl>
            </div>
            <div className="title">2) Find Our Plugin</div>
            <div className="find-plugin">
              Go to your website's Wordpress admin and select{' '}
              <b>PLUGINS &gt; ADD NEW</b> and then search for <b>Hellobar</b>.
              Press the <b>INSTALL NOW</b> button on the official Hello Bar
              plugin and <b>ACTIVATE</b>.<br />
              Need help locating the Hello Bar Wordpress Plugin?&nbsp;
              <a
                href="https://support.hellobar.com/support/solutions/articles/44001406925-how-to-locate-install-the-wordpress-plugin-for-hello-bar"
                rel="noreferrer"
                target="_blank"
              >
                Click here
              </a>
            </div>
            <div className="title">3) Paste Site Snippet</div>
            <p>
              Now you should see a new <b>Hello Bar</b> option in your WP admin
              side menu. Select that to open the plugin and paste the site
              snippet that you copied in <b>step 1</b>.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className="reveal-wrapper"
        square
        expanded={expanded === 'panel2.5'}
        onChange={handleChange('panel2.5')}
      >
        <AccordionSummary aria-controls="panel2.5d-content" id="panel2.5d-header">
          <i className="icon-gplus-circled" />
          <div className="summary-info">
            <div className="reveal-title">I use Google Tag Manager</div>
            <p>
              If you use Google Tag Manager (GTM), you can easily add Hello Bar through your GTM interface.
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="reveal-block gtm-reveal">
            <div className="title">1) Copy Your Hello Bar Script</div>
            <FormControl fullWidth>
              <TextField
                onClick={handleCopy}
                value={makeSnippet(site)}
                readOnly
                multiline
                rows={2}
                size="small"
              />
            </FormControl>
            <div className="title">2) Add to Google Tag Manager</div>
            <p>
              In your GTM account:
              <ol>
                <li>Click on <b>Add a New Tag</b></li>
                <li>Click on <b>Tag Configuration</b></li>
                <li>Select <b>Custom HTML</b></li>
                <li>Paste the Hello Bar script from step 1</li>
                <li>Name your tag (e.g. "Hello Bar Javascript")</li>
                <li>Under <b>Triggering</b>, select <b>All Pages</b></li>
                <li>Click <b>Submit</b> and then <b>Publish</b></li>
              </ol>
              It may take a few minutes for Hello Bar to recognize the installation.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className="reveal-wrapper"
        square
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <i className="icon-installmyself" />
          <div className="summary-info">
            <div className="reveal-title">I can install code myself</div>
            <p>
              If you know HTML and can edit your site, our install only takes
              one line of code.
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="reveal-block myself-reveal">
            <p>
              To install Hello Bar, copy and paste the following code right
              before your closing &lt;/body&gt; tag.
            </p>
            <FormControl fullWidth>
              <TextField
                onClick={handleCopy}
                value={makeSnippet(site)}
                readOnly
                multiline
                rows={2}
                size="small"
              />
            </FormControl>
            <br />
            <br />
            <p>
              That's it! You'll never need to update the code on your site
              again.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className="reveal-wrapper"
        square
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <i className="icon-wix" />
          <div className="summary-info">
            <div className="reveal-title">I use Wix</div>
            <p>
              If your site is hosted on Wix, we've got a super easy copy and
              paste integration. Get your trackng ID here.
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="reveal-block wix-reveal">
            <div className="title">1) Copy your ID:</div>
            <div className="form-inline">
              <FormControl fullWidth>
                <TextField
                  onClick={handleCopy}
                  value={site.snippet_id}
                  size="small"
                  readOnly
                />
              </FormControl>
            </div>
            <div className="title">2) Paste it to Wix</div>
            <p>
              Go to your <strong>Wix account</strong>, click on{' '}
              <strong>Promote &gt; Marketing integrations &gt;</strong> in the{' '}
              <strong>HelloBar widget</strong> click <strong>Go for it.</strong>
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className="reveal-wrapper"
        square
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <i className="icon-contacts" />
          <div className="summary-info">
            <div className="reveal-title">Email my Web Developer</div>
            <p>
              If someone else manages your website, we can email them the
              instructions for you.
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="reveal-block">
            <form acceptCharset="UTF-8" method="post" onSubmit={sendEmail}>
              <label>To:</label>
              <FormControl fullWidth>
                <TextField
                  required
                  label="Your developer's email address"
                  type="email"
                  onChange={devEmailChanged}
                  size="small"
                />
              </FormControl>
              <br />
              <br />
              <label>From:</label>
              <pre>Hello Bar &lt;contact@hellobar.com&gt;</pre>
              <label>Subject:</label>
              <pre className="topper">
                Please install Hello Bar on {site.url}
              </pre>
              <label>Body:</label>
              <br />
              <p>
                I just created a Hello Bar for {site.url} and I've asked Hello
                Bar to email you the instructions for installing it. All you
                need to do is add the following bit of JavaScript at the end of
                each page (right before the closing &lt;/body&gt; tag):
              </p>

              <pre>
                &lt;script type="text/javascript" src="https://my.hellobar.com/
                {site.snippet_id}.js" async="async"&gt;&lt;/script&gt;
              </pre>
              <p>
                If you have any questions don't hesitate to email Hello Bar
                support at support@hellobar.com, or e-mail me at {user.email}.
              </p>
              <p>Thank you!</p>
              <input
                type="submit"
                name="commit"
                value="Send"
                className="button button-green"
              />
            </form>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className="reveal-wrapper"
        square
        expanded={expanded === 'panel5'}
        onChange={openFreshchat}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <i className="icon-support" />
          <div className="summary-info">
            <div className="reveal-title">I need help!</div>
            <p>Still not sure what to do? Let us help!</p>
          </div>
        </AccordionSummary>
      </Accordion>
    </div>
  );
};

export default withTracking(Summary);
