import React, { useState } from 'react';
import Charts from '../shared/Charts';
import AppLoader from '../shared/AppLoader';
import Error from '../shared/Error';
import '../assets/styles/Summary.scss';
import '../assets/styles/_install.scss';
import '../assets/styles/_reveal.scss';
import '../assets/styles/_icons.scss';
import '../assets/styles/_charts.scss';
import useFetch from '../utils/useFetch';
import SummaryToolbar, { defaultDateRange } from './SummaryToolbar';
import { withTracking } from '../shared/Tracking';
import SiteLink from '../shared/SiteLink';

const stastics = [
  { type: 'total', text: 'VIEWS', icon: 'views', chart: 'views' },
  { type: 'call', text: 'CALLS', icon: 'call', chart: 'call' },
  {
    type: 'email',
    text: 'EMAILS COLLECTED',
    icon: 'contacts',
    chart: 'contacts',
  },
  { type: 'traffic', text: 'CLICKS', icon: 'clicks', chart: 'clicks' },
  {
    type: 'social',
    text: 'SOCIAL CONVERSIONS',
    icon: 'social',
    chart: 'social',
  },
];

const Summary = ({ site }) => {
  const [dateRange, setDateRange] = useState(defaultDateRange());
  const [currentSeries, setCurrentSeries] = useState(['total']);
  const {
    loading,
    error,
    status,
    data: { numbers, graphs },
  } = useFetch('summary', dateRange.from, dateRange.to);
  const [accordionStates, setAccordionStates] = useState({
    first: site.installed ? false : true,
    second: site.installed && !site.has_popup ? true : false,
  });

  // if (sessionStorage.getItem('preselected_plan')) {
  //   const plan = sessionStorage.getItem('preselected_plan');
  //   const upgradeUrl = generatePath("/sites/:id/upgrade/:plan", { id: site.id, plan });
  //   return (<Redirect to={upgradeUrl}/>);
  // }

  const updateChart = (type) => () => {
    setCurrentSeries((prevValue) => {
      if (prevValue.length === 1 && prevValue.includes(type)) {
        return ['total'];
      } else if (prevValue.includes(type)) {
        return prevValue.filter((value) => value !== type);
      } else {
        return [...prevValue, type];
      }
    });
  };

  const setRange = (range) => {
    setDateRange(range);
  };

  const toggleAccordion = (id) => {
    setAccordionStates((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div>
      {(!site.installed || !site.has_popup) && (
        <div className="onboarding-section">
          <div className="welcome-message">
            <h1>Hey There - Welcome to Hello Bar! 😊</h1>
            <p>
              Over 700,000 websites agree that Hello Bar is one of the easiest
              and most powerful ways to increase your conversions. 🚀
            </p>
            <br />
            <p>
              To get started, simply connect Hello Bar to your website and
              launch your first pop up.
            </p>
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => toggleAccordion('first')}
              style={{ cursor: 'pointer' }}
            >
              <span className="status-icon"> {site.installed ? '✓' : 1} </span>
              <h2>Connect Hello Bar</h2>
              <span className="time">2 mins</span>
              <span className={`arrow ${accordionStates.first ? 'open' : ''}`}>
                ▼
              </span>
            </div>
            {accordionStates.first && (
              <div className="accordion-content">
                <div className="content-grid">
                  <div className="left-content">
                    <h3>Connect Hello Bar To Your Website</h3>
                    <p>
                      Add our code snippet to your website to start showing
                      popups to your visitors.
                    </p>
                    <SiteLink to="/install">
                      <button className="get-started-btn">Install now</button>
                    </SiteLink>
                  </div>
                  <div className="right-content">
                    <div className="video-container">
                      <img
                        src="https://www.hellobar.com/wp-content/uploads/2018/05/bigstock-Concept-Ideas-With-Innovative-239076985.jpg"
                        alt="Install HelloBar"
                        style={{ width: '100%', height: '300px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => toggleAccordion('second')}
              style={{ cursor: 'pointer' }}
            >
              <span className="status-icon">{site.has_popup ? '✓' : 2}</span>
              <h2>Launch Your First Popup</h2>
              <span className="time">10 mins</span>
              <span className={`arrow ${accordionStates.second ? 'open' : ''}`}>
                ▼
              </span>
            </div>
            {accordionStates.second && (
              <div className="accordion-content">
                <div className="content-grid">
                  <div className="left-content">
                    <h3>Launch A Popup Now</h3>
                    <p>
                      Our no code popup creator will have you enjoying 83% more
                      conversions in no time.
                    </p>
                    <SiteLink to="/popups/new/interstitial">
                      <button className="get-started-btn">Get started</button>
                    </SiteLink>
                  </div>

                  <div className="right-content">
                    <div className="video-container">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/8ZTxWGvRd_o?si=UpXjnSHm9yBTIkCs"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {site.installed && site.has_popup && (
        <>
          <SummaryToolbar
            site={site}
            rangeChanged={setRange}
            initialRange={1}
          />
          <AppLoader visible={loading} />
          {status === 'error' && <Error error={error} />}
          {status === 'fetched' && (
            <React.Fragment>
              <div className="chart-wrapper tabs-data">
                {stastics.map((stat) => (
                  <div
                    className={[
                      'chart-block',
                      stat.chart,
                      currentSeries.includes(stat.type) && 'activated',
                    ].join(' ')}
                    data-chart=""
                    key={stat.type}
                    onClick={updateChart(stat.type)}
                  >
                    <i className={`icon-${stat.icon}`}></i>
                    <p className="chart-overview">
                      {numbers[`${stat.type}_humanized`]}
                      <small>{stat.text}</small>
                    </p>
                  </div>
                ))}
              </div>
              <Charts data={graphs} currentSeries={currentSeries} />
            </React.Fragment>
          )}
        </>
      )}
    </div>
  );
};

export default withTracking(Summary);
